import React from "react"
import { Modal, Row, Col } from "react-bootstrap"
import CloseIcon from "@material-ui/icons/Close"
import Splide from "../../splide"

const Index = props => {
  const { detailData, onHide, origin } = props

  const regexGetHrefAttribute = /<a[^>]*href=["']([^"']*)["']/gm
  const getTechnoparkImage = wysiwygMultipleGallery => {
    const getHrefAttribute = wysiwygMultipleGallery?.match(
      regexGetHrefAttribute
    )
    const getAllGalleryLink = getHrefAttribute?.map(
      item => `${item.substring(9, item.length - 1).replace(/ /g, "%20")}`
    )
    return getAllGalleryLink
  }

  const getTechnoparkValue = getTechnoparkImage(
    detailData?.technopark.uploadGambarTech
  )

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {detailData?.title || "Detail Technopark"}
        </Modal.Title>
        <CloseIcon className="remove-file" onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            {getTechnoparkValue && getTechnoparkValue.length > 0 ? (
              <Splide data={getTechnoparkValue} />
            ) : (
              <img
                src={`${
                  detailData?.image ||
                  "../../../../images/Berita_default-image.png"
                }`}
                alt="img-detail-technopark"
                className="img-chosen-detail-technopark"
                height="100%"
                width="100%"
              />
            )}
          </Col>
          <Col sm={12} md={6}>
            <strong>Deskripsi</strong>
            <div
              dangerouslySetInnerHTML={{
                __html: detailData?.technopark.deskripsiService,
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default Index
