import React, { useState, useEffect } from "react"
import { Container, Button, Row, Form, Col } from "react-bootstrap"
import Layout from "../components/layout/Index"
import { graphql } from "gatsby"
import Card from "../components/commons/card/technopark"
import Modal from "../components/commons/modal/detailTechnopark"
import { withTrans } from "../i18n/withTrans"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const Index = ({ data, t, i18n, location: { origin } }) => {
  const [userTechnopark, setUserTechnopark] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [modalShow, setModalShow] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [dataUser, setDataUser] = useState([])
  const [dataTechnopark, setDataTechnopark] = useState([])
  const classes = useStyles()
  const pageSize = 6
  const language = i18n.language

  useEffect(() => {
    let tempData = data.allWpUnivTechnopark.nodes
      .filter(o =>
        language === "id"
          ? o?.language?.code !== "EN"
          : o?.language?.code === "EN"
      )
      .map(item => ({
        id: item.id,
        name: item.title,
        ...item,
      }))
    setDataUser(tempData)
    if (tempData.length > 0) {
      setUserTechnopark(tempData[0])
    }
  }, [data, language])

  useEffect(() => {
    if (userTechnopark) {
      let tempData = data.allWpTechnopark.nodes.filter(
        item => item.technopark.kategoriUniversitas.id === userTechnopark.id
      )
      setDataTechnopark(tempData)
    }
  }, [userTechnopark])

  const roundPagesCount = Math.ceil(dataTechnopark.length / pageSize)
  const handlePageClick = (e, i) => {
    setCurrentPage(i)
  }

  return (
    <Layout>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        detailData={detailData}
        origin={origin}
      />
      <Container className="page-container technopark-container">
        <Row className="justify-content-between align-items-center mb-4">
          <Col md={12} className="page-title m-0">
            <span>{t("header.technopark")}</span>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="">
            <div className="container-header-technopark">
              <img
                src={
                  userTechnopark && userTechnopark.univTechnopark.logoHeaderUniv
                    ? userTechnopark.univTechnopark.logoHeaderUniv.sourceUrl
                    : "../../../images/img-technopark.png"
                }
                alt="bg-technopark"
                className="bg-technopark"
                height="100%"
                width="100%"
              />
              <div className="d-flex justify-content-center">
                <div className="filter-technopark">
                  <Col md={8} xs={12}>
                    <div className="techno-logo">
                      <div className="techno-logo-user h-100">
                        <img
                          src={
                            userTechnopark &&
                            userTechnopark.univTechnopark.logoUniv
                              ? userTechnopark.univTechnopark.logoUniv.sourceUrl
                              : "../../../images/logo-ui.png"
                          }
                          alt="logo-user"
                          className="img-technopark-user"
                          height="100%"
                          width="100%"
                        />
                      </div>
                      <div className="techno-logo-gakeslab">
                        <img
                          src="../../../images/ic_logo_gakeslab.png"
                          alt="logo-gakeslab"
                          className="img-technopark-gakeslab"
                          height="100%"
                          width="100%"
                        />
                        <span>GAKESLAB</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} xs={12} className="technopark-select">
                    <select
                      id="filter-technopark"
                      name="filter-technopark"
                      className="form-control"
                      onChange={e => {
                        let chosen = dataUser.filter(
                          item => item.id === e.target.value
                        )
                        if (chosen.length > 0) {
                          setUserTechnopark(chosen[0])
                        }
                      }}
                    >
                      {dataUser.map((item, idx) => (
                        <option key={idx} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ textAlign: "center", marginTop: 50, marginBottom: 12 }}>
          <span style={{ fontSize: 28, fontWeight: "bold" }}>
            {t("technopark.service")} {userTechnopark?.name}
          </span>
        </div>
        {dataTechnopark && dataTechnopark.length > 0 && roundPagesCount > 0 ? (
          <Row>
            <Col md={12} className="">
              <Row style={{ marginBottom: 20 }}>
                {dataTechnopark
                  .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map((item, i) => (
                    <Col
                      md={4}
                      sm={6}
                      xs={12}
                      style={{ padding: 0 }}
                      key={i}
                      className=""
                    >
                      <Card
                        title={item.title}
                        image={item.technopark.thumbnailTech?.sourceUrl}
                        onClick={() => {
                          setDetailData(item)
                          setModalShow(true)
                        }}
                      />
                    </Col>
                  ))}
              </Row>
              {dataTechnopark &&
                dataTechnopark.length > 0 &&
                roundPagesCount > 0 && (
                  <div
                    className={`${classes.root} d-flex justify-content-center mt-4`}
                  >
                    <Pagination
                      count={roundPagesCount}
                      page={currentPage}
                      onChange={handlePageClick}
                      shape="rounded"
                      color="primary"
                    />
                  </div>
                )}
            </Col>
          </Row>
        ) : (
          <div className="d-flex justify-content-center">{t("noData")}</div>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpTechnopark {
      nodes {
        title
        language {
          code
        }
        technopark {
          uploadGambarTech
          thumbnailTech {
            sourceUrl
          }
          deskripsiService
          kategoriUniversitas {
            ... on WpUnivTechnopark {
              id
              title
            }
          }
        }
      }
      totalCount
    }
    allWpUnivTechnopark {
      nodes {
        id
        title
        language {
          code
        }
        univTechnopark {
          logoHeaderUniv {
            sourceUrl
          }
          logoUniv {
            sourceUrl
          }
        }
      }
      totalCount
    }
  }
`

export default withTrans(Index)
